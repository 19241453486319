import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import Seo from "../components/common/seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fullDate } from "../utils/date_format";
import moment from 'moment';
import 'moment/locale/fr';


export const yearOnly = (date) => {
  const d = new Date(date);
  return d.getFullYear().toString();
};

export const FRmonthYearFormat = (date) => {
  return moment(date).locale('fr').format('MMMM YYYY');
};

export const monthYearFormat = (date) => {
  const d = new Date(date);
  const month = d.toLocaleString('default', { month: 'long' }); // This will get the full month name
  const year = d.getFullYear();
  return `${month} ${year}`;
};

const IndustryQAS = props => {
  const { allContentfulIndustryQAs } = props.data

  return (
    <div className="caseStudiesPage industryQAs">
      <Layout>
        <Seo title="Industry Q&As" />
        <div className="main_content_wrapper casestudies_blog">
          <div className="obie_awards_blog_section casestudies_blog">
            <div className="container">
              <div className="obie_awards_blog_inner_section">
                <div className="creative_library_dropdown">
                  <div className="common_section_title">
                    <h2 className="en-only">
                      Industry <span> Q&As </span>
                    </h2>
                    <h2 className="fr-only">
                    Questions et réponses <span> de L’Industrie </span>
                    </h2>
                  </div>
                </div>

                <div className="obie_awards_blog_row">
                  {allContentfulIndustryQAs.nodes.map((cs, i) => (
                    <div className="obie_awards_blog_col" key={cs.id}>
                      <div className="obie_awards_blog_info">
                        <a
                          href={cs.subtitle}
                          className="oa_blog_img"
                          target="_blank"
                        >
                          <GatsbyImage
                            image={getImage(cs.image)}
                            alt="casestudies blog"
                            className="slider_images"
                          />
                          <a
                            href={cs.subtitle}
                            className="awards_btn platinum_color"
                            target="_blank"
                          >
                            {yearOnly(cs.qAdate)}
                          </a>
                        </a>
                        <div className="oa_blog_details">
                          <span className="oa_blog_sub_text en-only">
                            {monthYearFormat(cs.qAdate)}  
                          </span>
                          <span className="oa_blog_sub_text fr-only">
                            {FRmonthYearFormat(cs.qAdate)}  
                          </span>
                          <a href={cs.subtitle} target="_blank">
                            <h3 className="oa_blog_title">{cs.title}</h3>
                          </a>
                          <a
                            href={cs.subtitle}
                            className="see_details_btn slider_images en-only"
                            target="_blank"
                          >
                            View Q&A
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <a
                            href={cs.subtitle}
                            className="see_details_btn slider_images fr-only"
                            target="_blank"
                          >
                            Voir les Q&R
                            <i
                              className="fa fa-caret-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default IndustryQAS

export const industryQas = graphql`
  query IndustryQAS($language: String) {
    allContentfulIndustryQAs(filter: { node_locale: { eq: $language } }, sort: { fields: qAdate, order: DESC}) {
      nodes {
        id
        image {
          gatsbyImageData
        }
        title
        subtitle
        qAdate
      }
    }
  }
`
